import TitleUnderline from "components/parts/TitleUnderline";
import React, { useState, useEffect } from "react";
import ProfileSection from "./ProfileSection";
import { Button, CircularProgress, styled } from "@mui/material";
import { useGetUserDetailsQuery } from "features/auth/authApiSlice";
import { useParams } from "react-router-dom";
import BackComp from "components/parts/BackComp";
import AddBillModal from "components/modals/AddBillModal";
import useFetch from "hooks/useFetch";
import BillsAccordion from "components/accordions/BillsAccordion";
import { izypromanService } from "apis";
import { AiOutlineDown } from "react-icons/ai";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const MonthlyRentTenantView = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { liveizy_id, apartment_id } = useParams();
  const { data: userData } = useGetUserDetailsQuery(liveizy_id);
  const [openApartment, setOpenApartment] = useState('');
  const [endpoint, setEndpoint] = useState('');

  const apartments = `/tenant-apartments/${liveizy_id}`;
  const { data: apartmentData, loading: apartmentIsLoading } = useFetch(izypromanService(), apartments);

  useEffect(() => {
    if (openApartment) {
      setEndpoint(`/bill/get_apartment_bills/${openApartment}`);
    }
  }, [openApartment]);

  const { data, loading, refetch } = useFetch(izypromanService(), endpoint);

  const handleOpen = () => setModalIsOpen(true);
  const handleClose = () => setModalIsOpen(false);

  if (apartmentIsLoading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div>;

  return (
    <div>
      <AddBillModal
        open={modalIsOpen}
        handleClose={handleClose}
        LID={userData?.data?.profile?.liveizy_id}
        AID={apartment_id}
        property={apartment_id}
        refetch={refetch}
      />
      <BackComp />
      <section>
        <TitleUnderline title={"Tenants Details"} />
        <ProfileSection tenant={userData?.data} />
      </section>

      {/* Apartments List */}
      {apartmentData?.data?.map((el) => (
        <AccordionDiv key={el._id}>
          <AccSummary
            expandIcon={<AiOutlineDown />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <PropertySummary>
              <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                <h4>
                  <strong>{el?.apartment_id}</strong>
                </h4>
              </div>
              <div>
                <Button
                  sx={{
                    width: "auto",
                    height: "40px",
                    fontSize: "11px",
                    color: "white",
                    background: "#10609F",
                  }}
                  onClick={() => setOpenApartment(el?.apartment_id)}
                >
                  View All
                </Button>
              </div>
            </PropertySummary>
          </AccSummary>
          <AccordionDetails style={{ marginTop: '3rem' }}>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                  <h2>IzyRent Monthly Bills</h2>
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button style={{ width: 'max-content' }} onClick={handleOpen} variant="contained">
                      Add Bill
                    </Button>
                  </div>
                </div>
                <hr />
                {/* Display bills for the selected apartment */}
                {openApartment ? (
                  <BillsAccordion data={data?.data} refetch={refetch} />
                ) : (
                  <p>Select an apartment to view bills.</p>
                )}
              </>
            )}

          </AccordionDetails>
        </AccordionDiv>
      ))}

    </div>
  );
};

export default MonthlyRentTenantView;

const AccordionDiv = styled(Accordion)`
  margin: 20px auto;
  width: 100%;
  padding: 0px;
  height: auto;
  font-size: 14px;
`;

const AccSummary = styled(AccordionSummary)`
  width: 100%;
  padding: 10px;
  height: 70px;
  margin: auto 0;
  background: white;
  box-shadow: 0px 4.2992px 31.1692px -8.59841px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const PropertySummary = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
`;

const ApartmentSummary = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 20px auto;
`;
